import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import NavbarLogo from "../../components/navbarLogo"
import { getReleaseNotes } from "../../actions"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

const ReleaseNotes = ({ location }) => {
  const [releaseNotes, setReleaseNotes] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeReleaseNote, setActiveReleaseNote] = useState()
  const [showSidemenu, setSidemenu] = useState(false)

  const toggleMenu = () => {
    setSidemenu(!showSidemenu)
  }

  const handleActiveRealeaseNote = releaseNote => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    })
    const url = new URL(window.location)
    url.searchParams.set("id", releaseNote.id)
    window.history.pushState(null, "", url.toString())
    setActiveReleaseNote(releaseNote)
  }

  useEffect(() => {
    fetchReleaseNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchReleaseNotes = async () => {
    try {
      const res = await getReleaseNotes()
      setLoading(false)
      if (res?.data?.content) {
        // eslint-disable-next-line react/prop-types
        const queryString = location.search
        const urlParams = new URLSearchParams(queryString)
        const releaseNoteId = urlParams.get("id")
        const releaseNotes = res.data.content
        const releaseNoteById = releaseNotes.find(release => release.id === releaseNoteId)
        setReleaseNotes(res.data.content)
        if (releaseNoteById) {
          setActiveReleaseNote(releaseNoteById)
        } else {
          setActiveReleaseNote(releaseNotes[0])
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Layout>
      <Helmet>
      </Helmet>
      <SEO title={"Release Notes"} titleDesc={"-"} titleKeywords={"-"} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

      {/* top section */}
      <div className={` ${showSidemenu ? "sidebar-show" : ""}`}>
        {/* sidebar */}
        <aside className={`training-sidebar`}>
          <Link to="/" className="navbar-brand">
            <NavbarLogo />
          </Link>

          <div className="training-title">Release Notes</div>

          <div className="training-menu">
            {/* loop */}
            {releaseNotes.map((releaseNote, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleActiveRealeaseNote(releaseNote)}
                  className={activeReleaseNote?.id === releaseNote?.id ? "menu-link active" : "menu-link"}
                >
                  <div>
                    <svg
                      width="18"
                      className="mr-2"
                      height="18"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line x1="5.5" y1="2.5" x2="5.5" y2="4.5" stroke="#ffffff" strokeLinecap="round" />
                      <line x1="10.5" y1="2.5" x2="10.5" y2="4.5" stroke="#ffffff" strokeLinecap="round" />
                      <line x1="3" y1="7.5" x2="13" y2="7.5" stroke="#ffffff" />
                      <path
                        d="M11.8889 3.44727H4.11111C3.26933 3.44727 2.5 4.07575 2.5 4.95306V11.9936C2.5 12.8709 3.26933 13.4994 4.11111 13.4994H11.8889C12.7307 13.4994 13.5 12.8709 13.5 11.9936V4.95306C13.5 4.07575 12.7307 3.44727 11.8889 3.44727Z"
                        stroke="#ffffff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {releaseNote.date}
                  </div>
                </div>
              )
            })}
          </div>
        </aside>

        {/* main content */}
        {loading ? (
          <div className="my-5 w-100 text-center">
            <img src="/images/loader.svg" alt="loading" />
            <p>Loading...</p>
          </div>
        ) : (
          <section className="note-content">
            <article className="container-fluid">
              <Row className="video-title align-items-center">
                <Col md={8} className="">
                  <div className="sidebar-toggle" onClick={toggleMenu}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="2.5" y1="3" x2="21.5" y2="3" stroke="white" strokeLinecap="round" />
                      <line x1="8.5" y1="9.5" x2="21.5" y2="9.5" stroke="white" strokeLinecap="round" />
                      <line x1="5.5" y1="16.5" x2="21.5" y2="16.5" stroke="white" strokeLinecap="round" />
                      <line x1="2.5" y1="22" x2="21.5" y2="22" stroke="white" strokeLinecap="round" />
                    </svg>
                  </div>

                  <div className="white-space" title={activeReleaseNote && activeReleaseNote.title}>
                    {activeReleaseNote && activeReleaseNote.title}
                  </div>
                </Col>
              </Row>

              {/* video player */}
              <Row className="justify-content-center">
                <Col md={11} className="my-3">
                  {activeReleaseNote && (
                    <>
                      <div>{activeReleaseNote.message}</div>
                      {activeReleaseNote.messages.map((message, messageIndex) => {
                        return (
                          <div key={activeReleaseNote.id} className="section">
                            <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
                            <>
                              {message.images.map((image, imageIndex) => {
                                return (
                                  <div key={imageIndex} className="img-hold">
                                    <img src={image.fileUrl} alt="" className="img-fluid" />
                                  </div>
                                )
                              })}
                            </>
                          </div>
                        )
                      })}
                    </>
                  )}
                </Col>
              </Row>
            </article>
          </section>
        )}
      </div>
    </Layout>
  )
}

export default ReleaseNotes
